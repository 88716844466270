import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { motion as m } from "framer-motion";

const ContactUs = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_0xgyt1o',
        'template_fcxclai',
        form.current,
        '4fDpcrqk4txfuXn0F'
      )
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      })
      .finally(() => {
        form.current.reset();
      });
  };

  return (
    <m.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.75, ease: "easeOut" }}
    >
      <div className="flex justify-center pt-28 pb-20">
        <div className="bg-black text-white max-w-6xl rounded-lg p-6">
          <div>
              <center>
                  <h1 className="font-bold text-[50px]">Contact Us</h1>
                  <p>Have a question, comment, or concern? Let us know!</p>
                  <p>Email <b className="text-[20px]">info@westmorebjj.com</b> or fill out the form below.</p>
              </center>
          <br/>
          </div>
          <form ref={form} onSubmit={sendEmail} className="space-y-4">
            <div>
              <label htmlFor="user_name" className="block text-sm font-medium">
                Name
              </label>
              <input
                type="text"
                id="user_name"
                name="user_name"
                className="border bg-black border-gray-300 w-48 px-3 py-2 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-gray"
              />
            </div>
            <div>
              <label htmlFor="user_email" className="block text-sm font-medium">
                Email
              </label>
              <input
                type="email"
                id="user_email"
                name="user_email"
                className="border bg-black border-gray-300 w-64 px-3 py-2 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-gray"
              />
            </div>
            <div>
              <label htmlFor="message" className="block text-sm font-medium">
                Message
              </label>
              <textarea
                id="message"
                name="message"
                className="border bg-black border-gray-300 w-[500px] h-[200px] px-3 py-2 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-gray"
              />
            </div>
            <div className="flex justify-center">
              <button
                type="submit"
                className="bg-gray hover:bg-blue-600 text-white font-medium px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-gray"
              >
                Send
              </button>
            </div>
          </form>
        </div>
      </div>
    </m.div>
  );
};

export default ContactUs;
