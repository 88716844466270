import React from "react";
import { motion as m } from "framer-motion";

const Discounts = () => {
    return (
        <m.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.75, ease: "easeOut" }}
        >
        <div className="flex justify-center pt-28 pb-20">
          <div className="max-w-sm md:max-w-4xl">
            <div className="flex flex-col gap-5 border-solid border-2 border-black bg-black rounded-lg p-8">
              <div className="flex justify-center gap-5">
                  <center>
                    <h1 className="font-extrabold text-xl md:text-5xl">Discount FAQ</h1>
                  </center>
              </div>
              <div className="mb-16">
                <div className="border border-yellow p-4 rounded-lg">
                  <p>
                  <b>Q: What is the Family Discount?</b>
                  </p>
                  <p>
                  <b>A:</b> Our Family Discount is designed for families who love to train together. When a family of 3 or
                  more members signs up, the total cost will be capped at $300. This includes access to our
                  all-inclusive kids and adults BJJ + Muay Thai unlimited flagship memberships. It's a great way
                  for the whole family to stay active and learn together!
                  </p>
                </div>
                <br/>
                <div className="border border-yellow p-4 rounded-lg">
                  <p>
                  <b>Q: Who is eligible for the Active Duty Discount?</b>
                  </p>
                  <p>
                  <b>A:</b> The Active Duty Discount is our way of showing appreciation to those who serve.
                  It's available to all active duty law enforcement, military, and first responders. Eligible
                  members will receive a 10% discount on their monthly membership, as a token of our gratitude
                  for their service.
                  </p>
                </div>
                <br/>
                <div className="border border-yellow p-4 rounded-lg">
                  <p>
                  <b>Q: How does the College Student Discount work?</b>
                  </p>
                  <p>
                  <b>A:</b> Our College Student Discount is tailored for currently enrolled college students. By providing
                  proof of college enrollment at the time of signing up, students can access a special pricing of
                  $99 per month. This discount applies exclusively to our adult BJJ program and cannot be combined
                  with other programs. It's an excellent opportunity for students to engage in BJJ at an affordable
                  price while studying.
                  </p>
                </div>
                <br/>
                <p>
                If you have additional questions or need more clarity on these discounts, please reach out to
                our management team directly at <b>(805)703-3249</b>.
                </p>
              </div>
            </div>
          </div>
        </div>
      </m.div>
    );
};

export default Discounts;
