import React from "react";
import { motion as m } from "framer-motion";

const Location = () => {
  return (
    <m.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.75, ease: "easeOut" }}
    >
        <div className="flex justify-center pt-28 pb-20">
            <div className="max-w-4xl border-solid border-2 border-black bg-black rounded-lg p-8">
                <div>
                    <center>
                    <h1 className="font-bold text-[50px]">Location</h1>
                    </center>
                </div>
                <br/>
                <center>
                    <div className="flex flex-row">
                        <div className="basis-1/2">
                            <p className="font-bold underline">Address</p>
                            <p>239 W. Tefft, Nipomo CA 93444</p>
                        </div>
                        <div className="basis-1/2">
                            <p className="font-bold underline">Phone</p>
                            <p>1 (805) 703-3249</p>
                        </div>
                    </div>
                </center>
                <br/>
                <center>
                    <div className="w-96 lg:w-128 h-64 lg:h-96">
                        <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d104643.46237750206!2d-120.50981409991356!3d34.95389669788054!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80ec69feecd6ff39%3A0x8e3ff178be88d3a1!2sCarlson%20Gracie%20Westmore!5e0!3m2!1sen!2sus!4v1686196016893!5m2!1sen!2sus"
                        title="Google Map"
                        className="w-full h-full"
                        allowFullScreen
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                    </div>
                </center>
            </div>
        </div>
    </m.div>
  );
};

export default Location;
