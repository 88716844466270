import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import cgw from "../images/westmorebjj.png";
import team from "../images/teamphoto.jpg";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { motion as m } from "framer-motion";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Main = () => {
  const images = [
    "https://i.imgur.com/QWEEE1L.jpeg",
    "https://i.imgur.com/MAU2Vjk.jpeg",
    "https://i.imgur.com/emrSXSb.jpeg",
    "https://i.imgur.com/blFHep9.jpeg",
    "https://i.imgur.com/TI8mDj3.jpeg",
    "https://i.imgur.com/ZA1TETM.jpeg",
    "https://i.imgur.com/NBbOA3W.jpeg",
    "https://i.imgur.com/NeuuFvp.jpeg",
    "https://i.imgur.com/9FzLBRL.jpeg",
    "https://i.imgur.com/6LRx88S.jpeg",
    "https://i.imgur.com/ks1t8qo.jpeg",
    "https://i.imgur.com/zIyNFB4.jpeg",
    "https://i.imgur.com/WkZyvrF.jpeg",
    "https://i.imgur.com/yhTdQMa.jpeg",
  ];

  const navigate = useNavigate();

  useEffect(() => {
    const handleClick = () => {
      navigate("/discounts");
    };

    toast.info(
      <div onClick={handleClick} style={{ cursor: "pointer" }}>
        <center>
          Check out our Family, Active Duty, & College Student DISCOUNTS!
        </center>
      </div>,
      {
        position: "top-center",
        autoClose: 20000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        toastId: "discounts",
      }
    );
  }, [navigate]);

  return (
    <m.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.75, ease: "easeOut" }}
    >
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <div className="flex justify-center pt-28 pb-20">
        <div className="max-w-sm md:max-w-4xl">
          <div className="flex flex-col gap-5 border-solid border-2 border-black bg-black rounded-lg p-8">
            <div>
              <img src={team} className="md:hidden rounded-lg" />
              <br />
            </div>
            <div className="flex items-center gap-5">
              <img src={cgw} className="w-24 md:w-52" />
              <center>
                <h1 className="font-extrabold text-xl md:text-5xl">
                  Westmore Brazilian Jiu Jitsu
                </h1>
              </center>
            </div>
            <div className="mb-16">
              <p>Welcome to Westmore Brazilian Jiu Jitsu!</p>
              <br />
              <p>
                At Westmore BJJ, our mission is to foster and build an inclusive
                community for individuals who share a passion for growth and
                personal development. We believe in the power of Brazilian Jiu
                Jitsu to transform lives, and we're dedicated to creating a
                supportive and immersive learning environment where everyone can
                thrive.
              </p>
              <br />
              <p>
                Located in the heart of the 805 on the Central Coast, our
                academy offers premier coaching and instruction for individuals
                of all ages and skill levels. Whether you're a child taking your
                first steps on the mat or an adult aiming to compete at the
                highest level, our experienced team is here to guide you on your
                journey.
              </p>
              <br />
              <p>
                We believe that Brazilian Jiu Jitsu is more than just a martial
                art; it's a healthy & creative way to express your growth as an
                individual while learning a vital survival skill. Through our
                comprehensive training programs, we empower our members to
                develop physical strength, mental resilience, and unwavering
                confidence.
              </p>
              <br />
              <p>
                Join Westmore Brazilian Jiu Jitsu today and experience the
                transformative power of our academy. Start your journey of
                personal growth, build lifelong friendships, and become the best
                versions of ourselves both on and off the mats.
              </p>
            </div>
          </div>
          <div></div>
          <div className="pt-8 hidden md:block">
            <Slide>
              <div className="each-slide-effect">
                <div style={{ backgroundImage: `url(${images[0]})` }}></div>
              </div>
              <div className="each-slide-effect">
                <div style={{ backgroundImage: `url(${images[1]})` }}></div>
              </div>
              <div className="each-slide-effect">
                <div style={{ backgroundImage: `url(${images[2]})` }}></div>
              </div>
              <div className="each-slide-effect">
                <div style={{ backgroundImage: `url(${images[3]})` }}></div>
              </div>
              <div className="each-slide-effect">
                <div style={{ backgroundImage: `url(${images[4]})` }}></div>
              </div>
              <div className="each-slide-effect">
                <div style={{ backgroundImage: `url(${images[5]})` }}></div>
              </div>
              <div className="each-slide-effect">
                <div style={{ backgroundImage: `url(${images[6]})` }}></div>
              </div>
              <div className="each-slide-effect">
                <div style={{ backgroundImage: `url(${images[7]})` }}></div>
              </div>
              <div className="each-slide-effect">
                <div style={{ backgroundImage: `url(${images[8]})` }}></div>
              </div>
              <div className="each-slide-effect">
                <div style={{ backgroundImage: `url(${images[9]})` }}></div>
              </div>
              <div className="each-slide-effect">
                <div style={{ backgroundImage: `url(${images[10]})` }}></div>
              </div>
              <div className="each-slide-effect">
                <div style={{ backgroundImage: `url(${images[11]})` }}></div>
              </div>
              <div className="each-slide-effect">
                <div style={{ backgroundImage: `url(${images[12]})` }}></div>
              </div>
              <div className="each-slide-effect">
                <div style={{ backgroundImage: `url(${images[13]})` }}></div>
              </div>
            </Slide>
          </div>
        </div>
      </div>
    </m.div>
  );
};

export default Main;
