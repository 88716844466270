import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Nav from "./components/Header";
import Main from "./components/Main";
import Footer from "./components/Footer";
import Instructors from "./components/Instructors";
import Schedule from "./components/Schedule";
import Location from "./components/Location";
import ContactUs from "./components/ContactUs";
import Pricing from "./components/Pricing";
import Discounts from "./components/Discounts";
import "./index.css";
import "tailwindcss/tailwind.css";
import "react-slideshow-image/dist/styles.css";

function App() {
  return (
    <>
      <div className="bg-lightblack min-h-screen text-white">
        <BrowserRouter>
          <Nav />
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="instructors" element={<Instructors />} />
            <Route path="schedule" element={<Schedule />} />
            <Route path="location" element={<Location />} />
            <Route path="contact" element={<ContactUs />} />
            <Route path="pricing" element={<Pricing />} />
            <Route path="discounts" element={<Discounts />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
