import React from "react";
import { NavLink } from "react-router-dom";
import { motion as m } from "framer-motion";

const PriceCard = ({ title, description, days, times, price, priceSuffix, cpm, cpc, link, target }) => {
  return (
    <div className="hover-div border-solid border-2 border-yellow md:border-black bg-black rounded-lg w-[20rem] p-8 flex flex-col">
      <div className="flex-grow">
        <p className="font-bold text-[30px]">{title}</p>
        <p className="text-[20px] pb-4">{description}</p>
        <p className="text-[20px] pb-4">{days}</p>
        <p className="text-[20px]">{times}</p>
        <p className="">
          <NavLink
            to="/schedule"
            className="block font-bold hover:text-yellow focus:text-gray p-6"
          >
            Refer to our Schedule
          </NavLink>
        </p>
        <p className="font-bold text-[25px]">{price}</p>
        <p className="pb-8">{priceSuffix}</p>
        <p className="font-bold">{cpm}</p>
        <p className="font-bold pb-4">{cpc}</p>
      </div>
      <NavLink
        to={link}
        className="rounded-full bg-red block px-2 pt-2 pb-2 font-bold mt-auto"
        target={target}
      >
        Select
      </NavLink>
    </div>
  );
};


const Pricing = () => {
  return (
    <m.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.75, ease: "easeOut" }}
    >
      <center>
        <div className="max-w-6xl pt-28 pb-20">
          <div>
            <h1 className="font-bold text-[50px] pb-10">Pricing</h1>
            <p className="font-bold text-[20px] pb-10">
              Check out our Family, Active Duty, & College Student
              <NavLink to="/discounts" className="text-yellow p-2">DISCOUNTS</NavLink>
              !
            </p>
            <div className="md:flex justify-evenly">
              <PriceCard
                title="ADULT BJJ"
                days="Schedule: M, T, W, Th, F, S"
                times="Times: 12:00pm - 1:15pm and 6:00pm-7:15pm"
                price="$130.00"
                priceSuffix="/ month"
                cpm="Classes per Month: 44"
                cpc="Cost per Class: $2.77"
                link="https://westmorebjj.gymdesk.com/signup?membership=38286&type=2"
                target="_blank"
              />
              <PriceCard
                title="KIDS BJJ"
                days="Schedule: Tuesdays and Thursdays"
                times="Time: 4:30pm - 5:15pm"
                price="$100.00"
                priceSuffix="/ month"
                cpm="Classes per Month: 8"
                cpc="Cost per Class: $12.50"
                link="https://westmorebjj.gymdesk.com/signup?membership=38287&type=2"
                target="_blank"
              />
              <PriceCard
                title="WRESTLING"
                days="Schedule: Sundays"
                times="Time: 12:30pm - 1:45pm"
                price="FREE"
                priceSuffix="Open to All"
                cpm=""
                cpc=""
                link="https://westmorebjj.gymdesk.com/signup/v/6Xnpq"
                target="_blank"
              />
            </div>
          </div>
        </div>
      </center>
    </m.div>
  );
};

export default Pricing;
