import React, { useEffect, useState } from 'react';
import { motion as m } from "framer-motion";

const Schedule = () => {

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://gymdesk.com/js/widgets.js';
    script.async = true;
    document.body.appendChild(script);


    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <m.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.75, ease: "easeOut" }}
    >
      <center>
        <div className="pt-28 pb-20">
          <div className="bg-black text-white max-w-6xl rounded-lg p-6">
            <div>
              <center>
                <h1 className="font-bold text-[50px]">Schedule</h1>
              </center>
              <br />
            </div>
            <div className="maonrails-schedule h-['500px']" attr-gym="ARJRB" attr-schedule="all" attr-program="all"></div>
          </div>
        </div>
      </center>
    </m.div>
  );
};

export default Schedule;
